@import 'lynxai-ui-kit/dist/theme-variables.scss';

.step-one {
    text-align: left;
    box-sizing: border-box;
    padding: var(--size-four);
    border-radius: var(--border-radius);

    @include themify($themes) {
        background-color: themed('card');
    }

    .form-div {
        display: flex;
        flex-flow: column;
        gap: var(--size-three);
    }
}