@import "../../../../assets/styles/global.scss";

.fund-profile-main-div {
    width: 100%;
    height: 100%;
    gap: var(--size-three);

    .fund-profile-content {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        gap: var(--size-three);

        .metrics-selection-div {
            position: relative;

            .placeholder {
                display: flex;
                align-items: center;
                gap: var(--size-one); 
            }
        }

        .no-selected-metrics {
            margin: var(--size-three) auto;
        }

        .metrics-board-analysis-main-div {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            box-sizing: border-box;
        }

        .table-div {
            margin: 0.5vw;
            width: 100%;

            .table-head {
                @include themify($themes) {
                    background-color: themed('background');
                }

                border: none;
                padding: var(--size-one) var(--size-three);

                .header-cell:hover {
                    color: var(--main-blue) !important;
                }

                .active {
                    @include themify($themes) {
                        color: themed('medium-color-50');
                    }
                }

                .sort-icon-active {
                    color: var(--main-blue);
                }
            }

            .table-body {
                background-color: pink;

                .no-assets-found-div {
                    display: flex;
                    width: 100%;
                    height: 30px;
                    justify-content: center;
                    align-items: center;
                }

                .table-row-cell {
                    @include themify($themes) {
                        background-color: themed('card');
                        border-top: 2.5px solid themed('background');
                        border-bottom: 2.5px solid themed('background');

                        .asset-profile-button {
                            background-color: transparent;
                            border: none;
                            width: 100%;
                            text-align: left;

                            &:hover {
                                color: var(--main-blue);
                            }
                        }
                    }

                    height: 35px;
                    padding: var(--size-two) var(--size-three);
                }

                .table-row-cell.limit-width {
                    max-width: 30vw;
                }
            }
        }
    }
}