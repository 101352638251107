@import 'lynxai-ui-kit/dist/theme-variables.scss';

.create-simulation-div {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    gap: var(--size-three);
    box-sizing: border-box;

    .create-simulation-inside-div {
        height: 100%;
        width: 100%;
        display: flex;
        flex-flow: column;
        box-sizing: border-box;
        gap: var(--size-three);
    }

    .create-simulation-menu-div {
        display: flex;
        flex-flow: column;
        width: fit-content;
        height: fit-content;
        padding: var(--size-two) 0;
        border-radius: var(--border-radius);
        box-sizing: border-box;

        @include themify($themes) {
            background-color: themed('left-menu');
        }
    }
}