@import 'lynxai-ui-kit/dist/theme-variables.scss';

.info-icon-div {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .info-icon {
        margin-left: var(--size-one);
        color: var(--main-blue);
    }

    .info-box {
        position: absolute;
        top: 0;
        display: inline-block;
        overflow: hidden;
        width: 20rem;
        white-space: normal;
        line-height: var(--size-three);
        height: fit-content;
        left: 30px;
        z-index: 3;
        border: 1px solid var(--main-blue);
        border-radius: var(--border-radius);
        padding: var(--size-two);

        @include themify($themes) {
            background-color: themed('card');
        }
    }
}