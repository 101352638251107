@import 'lynxai-ui-kit/dist/theme-variables.scss';

.filter-field-card-main-div {
    gap: var(--size-two);

    .filter-field-card-stat {
        border: 1px solid var(--main-blue);
        border-radius: var(--border-radius);
        width: 100%;
        height: fit-content;
        display: flex;
        flex-flow: column;
        box-sizing: border-box;
        justify-content: flex-end;
        padding: var(--size-three);
    }

    .filter-field-card-stat p {
        margin: var(--size-two) 0;
    }

    .filter-field-card-stat p:last-child {
        margin: var(--size-two) 0 0 0;
    }
}