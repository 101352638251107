@import 'lynxai-ui-kit/dist/theme-variables.scss';

.pai-no-funds-div {
  width: 100%;
  display: flex;
  align-items: center;
  height: var(--size-eleven);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: var(--size-four) 0;
}

.alert-box-info-div {
  display: flex;
  flex-flow: column;
}