@import 'lynxai-ui-kit/dist/theme-variables.scss';

.assets-history-main-div {
    display: flex;
    flex-flow: column;
    width: 100%;
    gap: var(--size-three);
    height: fit-content;
    position: relative;
    margin-bottom: 65px;

    .upper-div {
        @include themify($themes) {
            background-color: themed('medium-color-25') !important;
        }
        align-items: center;
        flex-flow: row !important;
        gap: var(--size-three);

        p {
            white-space: nowrap;
        }
    }

    .assets-history-dates-div {
        display: flex;
        position: absolute;
        top: calc(65px + var(--size-three));
        left: 0;
        box-sizing: border-box;
        overflow-x: auto;
        width: 100%;
        overflow-y: hidden;
        gap: var(--size-three);
        padding-top: var(--size-three);
        margin-bottom: var(--size-two);
    }

    .assets-history-dates-div.active-overflow {
        --mask-height: 20px;
        --mask-image-content: linear-gradient(to left,
                transparent,
                black var(--mask-height),
                black calc(100% - var(--mask-height)),
                transparent);
        --mask-size-content: 100% 100%;
        mask-image: var(--mask-image-content);
        mask-size: var(--mask-size-content);
        mask-position: 0 0, 100% 0;
        mask-repeat: no-repeat, no-repeat;
    }
}

.asset-history-management-date-div {
    display: flex;
    height: 35px;
    border-radius: var(--border-radius);
    align-items: center;
    padding: 0;

    @include themify($themes) {
        background-color: themed('medium-color-25');
    }

    .asset-history-management-date-button {
        display: flex;
        height: 100%;
        padding: 0 0 0 var(--size-three);
        align-items: center;
        border: none;
        background-color: transparent;

        p {
            text-align: left;
        }

        .calendar-icon {
            color: var(--main-blue);
            margin-right: var(--size-two);
        }

    }

    .remove-button {
        background-color: transparent;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;

        padding: 0 var(--size-two);

        .trash-icon {
            @include themify($themes) {
                color: themed('extreme-contrast');
            }
        }
    }

    .remove-button:hover {
        .trash-icon {
            color: var(--main-red)
        }
    }

}

.selected {
    background-color: var(--main-blue) !important;

    .asset-history-management-date-button {
        .calendar-icon {
            color: white;
        }
    }
}

.asset-history-management-date-div:hover {
    background-color: var(--main-blue);

    .calendar-icon {
        color: white;
    }
}


.add-date-form-div {
    margin-top: var(--size-three);
    display: flex;
    flex-flow: column;
}

.explanation-div {
    display: flex;
    gap: var(--size-three);
    flex-flow: column;

    p:first-child {
    width: 100%;
        box-sizing: border-box;
        margin-bottom: var(--size-three);
        line-height: var(--size-four);
    }

    p {
        line-height: var(--size-four);

        a {
            text-decoration: none;
            color: var(--main-blue);
            cursor: pointer;
        }

        a:hover {
            text-decoration: underline;
        }

        .icon {
            margin: 0 var(--size-two) -2px 0;
        }
    }

    p:last-child {
        margin-top: var(--size-three);
    }

}