@import 'lynxai-ui-kit/dist/theme-variables.scss';

.steps-main-div {
    width: 100%;
    display: flex;
    flex-flow: column;
    text-align: center;

    .copyright-factset-label {
        height: 15px;
        display: flex;
        align-items: center;
        text-align: left;
    }

    .articles {
        display: flex;

        @include themify($themes) {
            background-color: themed('card');
        }

        border-radius: var(--border-radius);
        flex-flow: column;
        padding: var(--size-two);

        .articles-div {
            transition: all 0.5s ease-in-out;
            -webkit-transition: all 0.5s ease-in-out;
            -o-transition: all 0.5s ease-in-out;
            -webkit-transition: all 0.5s ease-in-out;
            overflow: hidden;
            padding: 0 var(--size-three);

            label {
                @include themify($themes) {
                    color: themed('medium-color-50');
                }
            }

            p {
                margin-top: var(--size-two);
                line-height: var(--size-four);
                text-align: left;
            }

            .title {
                margin-top: var(--size-three);
                text-decoration: underline;
            }
        }
    }

    .references {
        @include themify($themes) {
            background-color: themed('card');
        }

        border-radius: var(--border-radius);
        padding: var(--size-two);
        display: flex;
        height: fit-content;
        flex-flow: column;
        margin-top: var(--size-two);

        .references-div {
            transition: all 0.5s ease-in-out;
            -webkit-transition: all 0.5s ease-in-out;
            -o-transition: all 0.5s ease-in-out;
            -webkit-transition: all 0.5s ease-in-out;
            overflow: hidden;
            padding: 0 var(--size-three);

            a {
                text-decoration: underline;
                margin: 0 var(--size-one);
            }

            a:visited {
                color: #b00000;
            }

            a:hover {
                text-decoration: underline;
                color: var(--main-blue);
            }

            p {
                margin-top: var(--size-two);
                line-height: var(--size-four);
                text-align: left;

                &:first-child {
                    margin-top: var(--size-three);
                }
            }
        }
    }
}

.collapse {
    visibility: collapse;
}

.steps-content-div {
    height: fit-content;
    width: 100%;

    .step-three-four-five {
        padding: var(--size-four);
        text-align: left;
        box-sizing: border-box;
        border-radius: var(--border-radius);

        @include themify($themes) {
            background-color: themed('card');
        }
    }
}