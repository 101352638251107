@import 'lynxai-ui-kit/dist/theme-variables.scss';

.container-footer {
  @include themify($themes) {
    background-color: themed('card');
  }

  border-radius: 0;
  display: block;
  margin: 0;
  justify-content: center;
  z-index: 6;
  height: fit-content;
}

.footer-content {
  display: grid;
  grid-template-columns: 30% 20% 20%;
  grid-template-rows: 22vw;
  grid-column-gap: 4%;
  justify-content: center;
  padding: var(--size-seven) 0;
  height: 8rem;
  max-width: 1500px;
  margin: 0 auto;
}

.footer-content-col {
  text-align: left;
  height: fit-content;
}

.footer-content-items {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: var(--size-four);
}

.footer-content-item {
  height: 30px;
  width: 100%;
}

.nav-link-footer {
  text-decoration: none;
  height: 30px;
  width: 50%;
  @include themify($themes) {
    color: themed('light-contrast');
  }
}

.nav-link-footer:hover {
  color: var(--main-blue)
}