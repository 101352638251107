@import 'lynxai-ui-kit/dist/theme-variables.scss';

.left-slide-menu-title {
    height: fit-content;
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.menu-sub-item-text {
    @include themify($themes) {
        color: themed('light-contrast');
    }
    cursor: pointer;
}

.menu-item-text {
    cursor: pointer;
    margin: var(--size-two) 0;
}

.left-slide-menu-list-item {
    line-height: var(--size-four);
    width: 100%;
    text-align: left;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    text-decoration: none;
}

.left-slide-menu-list-item .icon-open {
    transition: transform 0.5s ease-in-out;
    -webkit-transition: transform 0.5s ease-in-out;
    -o-transition: transform 0.5s ease-in-out;
    -webkit-transition: transform 0.5s ease-in-out;
}

.left-slide-menu-list-item .icon-close {
    transform: rotate(180deg);
    transition: transform 0.5s ease-in-out;
    -webkit-transition: transform 0.5s ease-in-out;
    -o-transition: transform 0.5s ease-in-out;
    -webkit-transition: transform 0.5s ease-in-out;
}

.left-slide-menu-item-main-div {
    height: fit-content;
    margin: var(--size-one) 0;

    button:hover {
        color: var(--main-blue)
    }
}

.left-slide-menu-list-subitem {
    max-height: 0;
    margin-left: var(--size-five);
    overflow: hidden;
    transition: max-height 0.75s cubic-bezier(0, 1, 0, 1);
    -webkit-transition: max-height 0.75s cubic-bezier(0, 1, 0, 1);
    -o-transition: max-height 0.75s cubic-bezier(0, 1, 0, 1);
    -webkit-transition: max-height 0.75s cubic-bezier(0, 1, 0, 1);
}

.left-slide-menu-list-item.plain {
    text-decoration: none;
}

.left-slide-menu-item-main-div.open > .left-slide-menu-list-subitem {
    transition: max-height 0.75s ease-in-out;
    -webkit-transition: max-height 0.75s ease-in-out;
    -o-transition: max-height 0.75s ease-in-out;
    -webkit-transition: max-height 0.75s ease-in-out;
    max-height: 600px;
}

#overlay {
    overflow-y: auto;
    position: absolute;
    @include themify($themes) {
        background-color: themed('background');
    }
    opacity: 0.8;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 4;
    transition: left linear 500ms;
    -webkit-transition: left linear 500ms;
    -o-transition: left linear 500ms;
    -webkit-transition: left linear 500ms;
    display: none;
}