@import 'lynxai-ui-kit/dist/theme-variables.scss';

.main-description-card {
    height: fit-content;
}

.description {
    @include themify($themes) {
    color: themed('light-contrast');
    }
    max-height: 132px;
    line-height: 22px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    transition: max-height 1s cubic-bezier(0, 1, 0, 1);
    -webkit-transition: max-height 1s cubic-bezier(0, 1, 0, 1);
    -o-transition: max-height 1s cubic-bezier(0, 1, 0, 1);
    -webkit-transition: max-height 1s cubic-bezier(0, 1, 0, 1);
    -webkit-line-clamp: 6;
}

.show-more {
    max-height: 1000px;
    transition: max-height 1s ease-in-out;
    -webkit-transition: max-height 1s ease-in-out;
    -o-transition: max-height 1s ease-in-out;
    -webkit-transition: max-height 1s ease-in-out;
    -webkit-line-clamp: 400;
}