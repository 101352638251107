@import "lynxai-ui-kit/dist/theme-variables.scss";

.header-div {
    align-items: center;
    justify-content: space-between;

    @include themify($themes) {
        background-color: themed('card');
    }

    display: flex;
    gap: var(--size-three);
    justify-content: center;
    z-index: 8;
    padding: 0 var(--size-seven);
    position: relative;
    height: fit-content;

    .navbar {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto var(--size-six);
        height: 80px;
        width: 100%;
        text-decoration: none;
    }

    .navbar-link {
        cursor: pointer;
        line-height: 60px;
        padding: 0 var(--size-three);
    }

    .navbar-link:hover {
        color: var(--main-blue)
    }

    .icon-div {
        display: flex;
        height: 100%;
        justify-content: flex-start;
        align-items: center;

        .name-img {
            height: var(--size-five);
            width: auto;
            margin: 0;
        }
    }

    .navbar-search-user-div {
        height: 100%;
        display: flex;
        gap: var(--size-four);
        align-items: center;
        justify-content: flex-end;
    }

    .navbar-user-div {
        width: fit-content;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
}

    .navbar-user-div .user-button-extended-dropdown {
        height: fit-content;
        width: 100%;
        background-color: transparent;
        padding: var(--size-two) 0;
        display: flex;
        align-items: flex-start;
        border: none;
        justify-content: flex-end;
        visibility: visible;

        .icon {
            right: 0;
            position: absolute;
            top: 0.7rem;
        }
    }

    .navbar-user-div .user-button-collapsed-dropdown {
        justify-content: flex-end;
        height: var(--size-six);
        border: none;
        gap: 6px;
        align-items: center;
        background-color: transparent;
        visibility: collapse;
        margin:  var(--size-two) 0;
        display: none;
    }

    .navbar-user-div .user-details-div {
        max-width: 200px;
        margin: auto var(--size-four);
        display: flex;
        height: var(--size-six);
        flex-flow: column;
        align-items: flex-end;
        justify-content: space-between;
    }

    .navbar-user-div .user-name {
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        text-align: right;
        width: 100%;
    }

    .navbar-user-div .user-name:last-child {
        @include themify($themes) {
            color: themed('medium-color-75');
        }
    }

    .navbar-user-div .navbar-user-options-dropdown {
        position: absolute;
        display: none;
        flex-flow: column;
        max-width: 250px;
        right: 0;
        top: 3rem;
        width: 200px;
        min-width: 130px;
        height: fit-content;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
        border-radius: var(--border-radius);
        padding: var(--size-two) 0;

        @include themify($themes) {
            background-color: themed('dropdown');
        }
    }

    .navbar-user-div:hover .navbar-user-options-dropdown {
        display: flex;
    }

    .dropdown-list-block {
        display: flex;
        flex-flow: column;
    }
}

@media screen and (max-width: 1280px) {
    .header-div {
        grid-template-columns: 11vw 70vw 19vw;

        .icon-div {
            margin-left: 1.5vw;
        }

        .navbar-user-div .user-button-extended-dropdown {
            display: none;
            visibility: collapse;
        }

        .navbar-user-div .user-button-collapsed-dropdown {
            display: flex;
            visibility: visible;
        }

        .navbar-search-user-div {
            right: 1.5vw;
        }
    }
}

@media screen and (max-width: 1000px) {
    .header-div {
        .navbar {
            margin: auto 0;
        }
    }
}