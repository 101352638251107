@import 'lynxai-ui-kit/dist/theme-variables.scss';

.optional-metrics-selection-div {
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 100%;
    margin: var(--size-two) 0;

    table {
        height: fit-content;
        text-align: left;
        width: 100%;

        tbody tr .checkbox-td {
            text-align: center;
        }

        .hovered-row {
            @include themify($themes) {
                background-color: themed('medium-color-25');
            }

            cursor: pointer;
        }

        .unhovered-row {
            cursor: pointer;
        }

        tbody tr td {
            @include themify($themes) {
                border: 1px solid themed('light-contrast') !important;
            }
        }

        tbody tr td:first-child {
            border-left: none !important;
        }

        tbody tr td:last-child {
            border-right: none !important;
        }

        tbody {
            @include themify($themes) {
            }
        }

        tbody:last-child {
            @include themify($themes) {
            }
        }

        td {
            padding: 0.75rem;
            vertical-align: middle;
        }

        td .value-div {
            display: flex;
            align-items: center;
        }

        th {
            padding: var(--size-two) 0.75rem;
            border-top: 1px solid var(--main-blue);
            border-bottom: 1px solid var(--main-blue);
            vertical-align: middle;
        }

        .col-small {
            width: 5%;
        }

        .col-medium {
            width: 35%;
        }

        .col-big {
            width: 60%;
        }

        th:first-child {
            @include themify($themes) {
                border-left: none;
            }
        }

        th:last-child {
            @include themify($themes) {
                border-right: none;
            }
        }

        .td-input {
            padding: 0px;
            height: 100%;
        }
    }
}