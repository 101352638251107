@import 'lynxai-ui-kit/dist/theme-variables.scss';

.sfdr-table-page-menu {
    width: 100%;
    margin: 0 auto var(--size-three) auto;
    padding-top: var(--size-three);
    height: 50px;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.pai-no-data-div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
}

.pai-table-div {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 8px 0;

    table {
        height: fit-content;
        text-align: left;
        width: 100%;
    }

    table tbody tr td:first-child {
        border-left: none !important;
    }

    table tbody tr td:last-child {
        border-right: none !important;
    }

    table tbody tr td {
        @include themify($themes) {
            border: 1px solid themed('light-contrast') !important;
        }
    }

    table td {
        padding: 0.75rem;
        vertical-align: middle;
    }

    table td .value-div {
        display: flex;
        align-items: center;
    }

    table th {
        padding: var(--size-two) 0.75rem;
        border-top: 1px solid var(--main-blue);
        border-bottom: 1px solid var(--main-blue);
        vertical-align: middle;
    }

    table th[colspan="7"] {
        border: none;

        @include themify($themes) {
            background: themed('medium-color-50');
        }

        height: 15px;
    }

    table .col-small {
        width: 10%;
    }

    table .col-medium {
        width: 16%;
    }

    table .col-big-big {
        width: 22%;
    }

    table th:first-child {
        @include themify($themes) {
            border-left: none;
        }
    }

    table th:last-child {
        @include themify($themes) {
            border-right: none;
        }
    }

    tbody:nth-child(even) td {
        @include themify($themes) {
            background-color: themed('card');
        }
    }

    .text-input-textarea {
        width: 100%;
        padding: 3px var(--size-one);
        box-sizing: border-box;
        overflow: scroll;
        resize: vertical;
        max-height: 200px;
        border: none !important;
        min-height: 35px;
        height: 100%;
        overflow: hidden;
    }

    .td-input {
        padding: 0px;
        height: 100%;
    }
}