@import 'lynxai-ui-kit/dist/theme-variables.scss';

.local-loader-container {
    @include themify($themes) {
        background-color: transparent;
    }
    
    min-height: 400px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

img {
    width: 100px;
    margin: auto;
    display: block;
}

.textarea-input {
    min-height: 35px;
    height: 100%;
    background-color: transparent !important;
}

.textarea-input img {
    width: 30px;
}

.textarea-big-input {
    min-height: 130px;
    max-height: 260px;
    height: 100%;
    background-color: transparent !important;
}

.textarea-big-input img {
    width: 80px;
}
