@import 'lynxai-ui-kit/dist/theme-variables.scss';

.info-table-icon-div {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .info-icon-table {
        margin-right: 6px;
        color: var(--main-blue);
    }

    .info-box-table {
        position: absolute;
        top: -25;
        display: inline-block;
        overflow: hidden;
        width: 150px;
        white-space: normal;
        line-height: 15px;
        height: fit-content;
        left: 30px;
        z-index: 3;

        @include themify($themes) {
            background-color: themed('card');
        }

        border: 1px solid var(--main-blue);
        border-radius: var(--border-radius);
        padding: var(--size-two);
    }

}