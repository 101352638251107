@import 'lynxai-ui-kit/dist/theme-variables.scss';

.asset-profile-main-div {
    width: 100%;
    height: 100%;

        .title-div {
            display: flex;
            align-items: flex-end;
            min-width: 100px;
            gap: var(--size-three);

            .title {
                white-space: nowrap;
                height: 100%;
                width: 100%;
            }

            .ticker {
                display: flex;
                height: 100%;
                align-items: flex-end;
            }
        }

    .asset-profile-content {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        gap: var(--size-three);
    }
}