

@import 'lynxai-ui-kit/dist/theme-variables.scss';

.big-one-value-card-value-div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.big-one-value-card-value-div .ring {
    width: 65%;
    height: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    border: 10px solid #FF8042;
}