@import 'lynxai-ui-kit/dist/theme-variables.scss';

.authentication-wrapper {
    @include themify($themes) {
        background-color: themed('background');
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: relative;

    .authentication-main-div {
        position: relative;
        width: 400px !important;
        padding: var(--size-six) !important;

        .back-button {
            background-color: transparent;
            border: none;
            position: absolute;
            top: 20px;
            left: 20px;
        }
    }

    .support-authentication-main-div {
        width: 600px !important;
        min-height: 250px !important;

        .back-button {
            background-color: transparent;
            border: none;
            position: absolute;
            top: 20px;
            left: 20px;
        }

        .bottom-note {
            a {
                text-decoration: none;
                color: var(--main-blue);
                cursor: pointer;
            }

            a:hover {
                text-decoration: underline;
            }
        }
    }

    .bottom-right-div {
        display: flex;
        height: 40px;
        justify-content: flex-start;
        align-items: center;
        gap: var(--size-two);
        position: absolute;
        bottom: 2.5vw;
        right: 2.5vw;
    }

    img {
        height: auto;
        width: 75%;
        max-width: 250px;
        margin: 0 auto;
    }

    .back-button:hover {
        color: var(--main-blue)
    }

    .icon-cross {
        color: var(--main-red);
        margin-right: var(--size-one);
    }

    .icon-check {
        color: var(--main-green);
        margin-right: var(--size-one);
    }


}